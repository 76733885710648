import btnAuthority from '@/modules/mdm/btnAuthority.js';
import otherUtils from '@/modules/mdm/utils/otherCommonFunc.js';
const FLOW_STATUS = ['1', '5', '6']; // 草稿、撤回和驳回
export const config = {
    name: 'flowAbandom',
    searchFields: [
        {
            name: '流程类型',
            value: 'mipType',
            isFixed: true,
            optionNum: 'MIP_TYPE',
            type: 'select',
            span: 3
        },
        {
            name: '单据编号',
            value: 'contractCode',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: 'MIP流程编号',
            value: 'mipId',
            isFixed: true,
            type: 'input',
            span: 3
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: '流程类型',
                prop: 'mipType',
                type: 'select',
                optionsKey: 'MIP_TYPE',
                minWidth: 120
            },
            {
                label: '单据编码',
                prop: 'contractCode',
                minWidth: 150
            },
            {
                label: '审批状态',
                prop: 'mipStatus',
                type: 'select',
                optionsKey: 'SYS_SU_MIP_AUDIT_STATUS',
                minWidth: 120
            },
            {
                label: 'MIP流程链接',
                prop: 'mipUrl',
                minWidth: 150
            },
            {
                label: '法务流程链接',
                prop: 'legalMipUrl',
                minWidth: 150
            },
            {
                label: '创建人',
                prop: 'createUserCode',
                minWidth: 150
            },
            {
                label: '创建时间',
                prop: 'createTime',
                minWidth: 150
            },
            {
                label: '修改人',
                prop: 'updateUserCode',
                minWidth: 150
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                minWidth: 150
            },
            {
                label: 'MIP流程编号',
                prop: 'mipId',
                minWidth: 160
            }
        ]
    },
    PANELMAX: 8,
    LASTFOUR: 4,
    LASTSIX: 6,
    actions: { // 表格操作列的配置
        fixedWidth: 80, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
        list: [
            {
                label: '特权废弃',
                event: 'abandom',
                displayRule: (row) => {
                    // 草稿状态 配置 PRIVILEGE_ABANDON_IFLOW_MIP
                    const isAbandom = FLOW_STATUS.includes(
                        row.mipStatus?.toString()
                    );
                    // 权限
                    const isAuth = otherUtils.getHasAuth(
                        btnAuthority.contractManage.subFlowAbandom
                    );
                    return isAbandom && isAuth;
                }
            }
        ]
    }
};
