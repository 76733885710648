import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 分页查询流程信息 zxh
export const flowAbandomList = (data) => {
    return request({
        url: apiCrmHost + '/contract/workflow/info/page',
        method: 'post',
        data
    });
};
