<!-- eslint-disable max-lines -->
<template>
    <div class="contract-manage">
        <Paper class="contract-manage-paper">
            <el-tabs
                class="contract-list-tab-pane"
                v-model="activeTabs"
                @tab-remove="removeTab"
                @tab-click="switchTabs">
                <!-- 合同列表页 -->
                <el-tab-pane
                    label="特权操作"
                    name="theList"
                    ref="myPane"
                    v-loading="holePageLoading">
                    <list-layout-paper>
                        <template slot="header-search" class="searchBox">
                            <search-box
                                ref="searchBox"
                                :search-key="config.name"
                                :fields="config.searchFields"
                                :showResetBtn="true"
                                @search-change="floowList.searchList"
                                @elementChange="elementChange"
                            />
                        </template>
                        <template slot="header-button">
                            <lotsButton
                                type="primary"
                                size="mini"
                                title="刷新"
                                @click="refresh"
                                plain>
                                <span class="icon iconfont icon-refresh"></span>
                            </lotsButton>
                            <el-tooltip effect="dark" :content="'列配置'" placement="bottom">
                                <el-button
                                icon="iconfont icon-list_install"
                                type="primary"
                                size="mini"
                                :title="'列配置'"
                                @click="showColumnConfigDialog"
                                plain />
                            </el-tooltip>
                        </template>
                        <!-- 表格 -->
                        <template v-slot:list="{ tableHeight }">
                            <table-box
                                ref="clientOrder"
                                :height="tableHeight"
                                v-loading="floowList.tableLoading"
                                :selection="config.selection"
                                :index="config.index"
                                :columns="config.tableData.columns"
                                :rows="floowList.totalData"
                                :actions="config.actions"
                                @action-click="actionClick"
                                @selection-change="handleSelectionChange">
                                <template v-slot:col-append="{ col, row }">
                                    <div v-if="col.prop === 'businessType'">{{col.prop === 'businessType' ? row.busiLabelList : row.businessType}}</div>
                                </template>
                            </table-box>
                        </template>
                        <template slot="footer">
                            <lots-pagination
                                @size-change="floowList.sizeChange"
                                :current-page.sync="floowList.pageNo"
                                @current-change="floowList.pageChange"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="floowList.pageTotal" >
                            </lots-pagination>
                        </template>
                    </list-layout-paper>
                </el-tab-pane>

            </el-tabs>
            <!-- 表格列配置弹框 -->
            <column-config
                :pageID="config.name"
                :initColumns="config.tableData.columns"
                ref="otpColumnConfig"
                @header-change="config.tableData.columns = $event"
            />

        </Paper>
    </div>
</template>
<script>
import Paper from '@/components/core/Paper';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import tableBox from '@/components/lots/tableBox/Index';
import { flowAbandomList } from '@mdm/api/basicSettings/flowAbandom.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import { ref, reactive, onMounted } from '@vue/composition-api';
import { Message } from 'element-ui';
import utils from '@/components/utils/common.js';
import lotsButton from '@/components/lots/lotsButton';
import columnConfig from '@/components/lots/columnConfig/Index';
import store from '@/store';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import bigDataLog from '@/utils/bigDataLog.js';
import { contractFlowAbandom } from '@mdm/api/contractManage/contractFootPageApi.js';
export default {
    name: 'flowAbandom',
    components: {
        Paper,
        listLayoutPaper,
        searchBox,
        tableBox,
        lotsPagination,
        lotsButton,
        columnConfig
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        const tenantCode = store.getters.currentTenant.tenantCode;
        onMounted(async () => {
            // setupContext.refs.searchBox.submitSearch();
        });
        class FloowListClass extends AdvanceListClass {
            beforeGetList(condition) {
                bigDataLog.sendDetailMessageByKey('bigData-btn-listPageSearch'); // 搜索埋点
                if (condition.contractCode === '' && condition.mipId === '' && condition.mipType === '') {
                    Message.warning('请添加查询条件');
                    return false;
                };
                return searchDataFormat(condition);
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list;
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const floowList = new FloowListClass({
            Api: {
                listApi: flowAbandomList, // 查询接口
                deleteApi: contractFlowAbandom // 删除行接口
            }
        });
        const searchDataFormat = (condition) => {
            return condition;
        };
        const config = reactive(configStatic);
        const holePageLoading = ref(false);
        const selectedList = ref([]);
        const refresh = () => {
            floowList.getList();
        };
        const elementChange = (data) => {
            // console.log('搜索改变data', data);
        };
        const handleSelectionChange = (data) => {
            selectedList.value = data;
        };
        const otpColumnConfig = ref(null);
        const showColumnConfigDialog = () => {
            otpColumnConfig.value.show(true);
        };
        const activeTabs = ref('theList');
        const removeTab = (targetName) => { // 删除详情标签
            if (activeTabs.value === targetName) {
                activeTabs.value = 'theList';
            }
            tabList.value = tabList.value.filter(
                (tab) => (tab.code + tab.detailPageState) !== targetName
            );
        };
        const switchTabs = (data) => {
            const comKey = `datailTabs${data.name}`;
            if (setupContext.refs[comKey] && setupContext.refs[comKey].length) {
                const { activeName } = setupContext.refs[comKey][0];
                setupContext.refs[comKey][0].handleClickTab({ name: activeName }); // 解决切换详情页tab子页签编辑表格错位问题
            }
        };
        const tabList = ref([]); // 打开的详情tab页集
        const actionClick = (event, row) => {
            if (event === 'abandom') {
                floowList.deleteRow({ mipId: row.mipId }, {
                    msg: '请确认是否特权废弃该流程？该操作不可恢复',
                    successMsg: '特权废弃成功！'
                });
            }
        };
        return {
            floowList,
            config,
            selectedList,
            refresh,
            handleSelectionChange,
            otpColumnConfig,
            showColumnConfigDialog,
            activeTabs,
            removeTab,
            switchTabs,
            tabList,
            actionClick,
            holePageLoading,
            tenantCode,
            utils,
            authorityCode,
            elementChange
        };
    }
};
</script>
<style lang="less">
.contract-manage {
    width: 100%;
    .contract-manage-paper {
        height: 100%;
    }
    .list-layout-wrapper {
        height: 100%;
    }
    .contract-list-tab-pane.el-tabs {
        width: 100%;
        height: 100%;
        & > .el-tabs__content {
            height: calc(100% - 40px - 14px);
            overflow: auto;
            & > .el-tab-pane {
                overflow: auto;
                padding-right: 5px;
                height: 100%;
                .list-main {
                    .table-box {
                        height: 100%;
                    }
                }
            }
        }
    }
    .flex-layout .list-main {
        overflow: auto;
    }

    .flex-layout .table {
        overflow-y: auto;
    }
    .list-header {
        .contract-manage-list-dropdown.el-dropdown {
            color: #fff;
        }
    }

    .layout-content.contract-manage-paper {
        padding: 0px 20px 20px;
        .el-table .cell.el-tooltip {
            &>div {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .list-layout-wrapper {
        padding: 0px;
    }
}
.contract-manage-list-dropdown-menu {
    background-color: #fff;
}
.column-config_title {
    font-size: 14px;
}
</style>
